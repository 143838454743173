import { ReactWrapper } from '$pages/common/react-wrapper';
import NotFound, { INotFoundProps, NotFoundUrlParams } from './not-found.react';

export class NotFoundWrapper extends ReactWrapper<INotFoundProps, NotFoundUrlParams> {
  constructor() {
    super(NotFound, params => ({
      params
    }));
  }
}

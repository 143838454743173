import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import React, { FC } from 'react';
import { Card, CardContent } from '@mui/material';

export interface INotFoundProps {
  params?: NotFoundUrlParams
}

export interface NotFoundUrlParams {
  type?: NotFoundEnum
}

enum NotFoundEnum {
  site = "site",
  controller = "controller"
}

const NotFound: FC<INotFoundProps> = ({ params }) => {
  const [t] = useCaseInsensitiveTranslation()

  const getDescription = () => {
    switch (params?.type) {
      case NotFoundEnum.site:
        return t("UI_NotFound_Description_Site")
      case NotFoundEnum.controller:
        return t("UI_NotFound_Description_Controller")
      default:
        return t("UI_NotFound_Description_First")
    }
  }

  const getTitle = () => {
    switch (params?.type) {
      case NotFoundEnum.site:
        return t("UI_NotFound_Title_Site")
        case NotFoundEnum.controller:
        return t("UI_NotFound_Title_Controller")
      default:
        return t("UI_NotFound_Header")
    }
  }

  return (
    <Card elevation={4} sx={{ margin: "1rem auto", width: 500, maxWidth: 500 }}>
      <CardContent>
        <a href='/'>Go to dashboard</a>
      </CardContent>
      <CardContent className="flex column center-content">
        <h1 style={{ fontSize: "5rem", margin: 0 }}>404</h1>
        <h1>{getTitle()}!</h1>
        <img src="/svg/yt3_character_404.svg" width={300} />
      </CardContent>
      <CardContent>
        <p> {getDescription()}</p>
      </CardContent>
    </Card>
  );
};

export default NotFound;